@import "../rgbstyle/rgbstyle-mixins";
@import "../rgbstyle/rgbstyle-variables";

.el-lista-subpaginas-imprensa {
    padding: 30px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @include tablet {
        padding: 50px 0;
    }
    @include desktop-hd {
        padding: 80px 0;
    }
    @include mq-min(1920) {
        padding: valor-fluido(80, 1920) 0;
    }

    .top {
        display: flex;
        flex-direction: column;
        @include desktop {
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            margin-bottom: 50px;
        }

        .left {
            @include desktop {
                width: 30%;
            }

            .v {
                @include desktop {
                    margin-bottom: 20px;
                }

                h2 {
                    color: $color;
                    font-size: 20px;
                    text-align: center;
                    font-weight: normal;
                    text-transform: uppercase;

                    @include desktop {
                        width: 50%;
                        text-align: left;
                        margin: 0;
                        &::before {
                            display: block;
                        }
                    }

                    &::before {
                        content: '';
                        position: relative;
                        left: -56px;
                        width: 50px;
                        height: 1px;
                        background: $color;
                        top: 14px;
                    }

                    b, strong {
                        width: 100%;
                        display: block;
                        font-size: 40px;
                        font-weight: bold;
                        color: $color_destaque;
                    }
                }
            }

            .item {
                display: none;
                margin-bottom: 0;
                @include desktop {
                    display: block;
                }

                .img {
                    @include img-box;
                    margin-bottom: 10px;
                }

                .info {
                    text-align: center;
                    @include desktop {
                        text-align: left;
                    }

                    .titulo {
                        color: $color;
                        margin: 0;
                        @include caixa-texto(18, 1.4, 2, false);
                    }
                }

                &:hover .info {
                    .titulo {
                        color: $color_destaque;
                    }
                }
            }
        }

        .right {
            @include desktop {
                width: 50%;
            }

            .video {
                position: relative;
                display: block;
                width: 100%;
                margin-bottom: 20px;
                @include desktop {
                    margin-bottom: 0;
                }
                @include desktop-hd {
                    width: auto;
                }

                .hover {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    padding: 10px;
                    background-color: rgba(#0e71b8, 0.55);
                    opacity: 0.5;
                    @include animado;
                    @include mq-min(1920) {
                        padding: valor-fluido(10, 19201);
                    }

                    .center {
                        min-width: 92px;
                        padding-top: 57px;
                        font-size: 20px;
                        text-transform: uppercase;
                        color: #ffffff;
                        text-align: center;
                        background-image: url(../../imagens/icone/icone-play-imprensa.png);
                        background-position: top center;
                        background-repeat: no-repeat;
                        background-size: 77px;
                        @include mq-min(1920) {
                            min-width: valor-fluido(92, 1920);
                            padding-top: valor-fluido(90, 1920);
                            font-size: valor-fluido(20, 1920);
                            background-position: center center;
                        }
                    }
                }

                &:hover .hover {
                    opacity: 1;
                }

                img {
                    display: block;
                    margin: 0 auto;
                    width: 100%;

                    height: auto;
                    @include desktop-hd {
                        max-width: 570px;
                    }
                }
            }
        }
    }


    .itens {
        @include tablet {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .item {
            display: block;
            margin-bottom: 20px;
            @include tablet {
                width: 49%;
                &:nth-child(n) {
                    margin-left: 2%
                }
                &:nth-child(2n+1) {
                    margin-left: 0
                }
            }
            @include desktop {
                width: 32%;
                &:nth-child(n) {
                    margin-left: 2%
                }
                &:nth-child(3n+2) {
                    margin-left: 0
                }
            }

            &:first-child {
                @include desktop {
                    display: none;
                }
            }

            .img {
                @include img-box;
                margin-bottom: 10px;
            }

            .info {
                text-align: center;
                @include desktop {
                    text-align: left;
                }

                .titulo {
                    color: $color;
                    margin: 0;
                    @include caixa-texto(18, 1.4, 2, false);
                }
            }

            &:hover .info {
                .titulo {
                    color: $color_destaque;
                }
            }
        }
    }
}